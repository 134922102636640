import React, {useMemo, useRef, useState} from "react";
import ReactQuill, {Quill} from "react-quill";
import "./Write.css";
import logo from "../../assets/images/logo.png";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {getAuth} from "firebase/auth";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import {Loading} from "../loading/Loading";
import {doc, getFirestore, setDoc, updateDoc} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import ImageResize from 'quill-image-resize';

Quill.register('modules/ImageResize', ImageResize);

export function Write() {
    const quillRef = useRef(null);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState("");
    const [hashtags, setHashtags] = useState([]);

    const getFileDataUrl = (file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
    };

    const imageHandler = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.setAttribute("multiple", "multiple");
        input.click();

        input.addEventListener("change", async () => {
            const storage = getStorage();

            const editor = quillRef.current.getEditor();
            const range = editor.getSelection(true);

            try {
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files[i];

                    const storageRef = ref(storage, `image/${Date.now()}`);

                    await uploadBytes(storageRef, file).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            editor.insertEmbed(range.index, "image", url);
                            editor.setSelection(range.index + 1);
                        });
                    });
                }
            } catch (error) {
                alert("서버와의 통신에 실패하였습니다.");
            }
        });
    };

    const addHashtag = (event) => {
        const inputVal = event.target.value;

        if (event.key === "Enter" && inputVal !== "" && !hashtags.includes(inputVal)) {
            if (hashtags.length > 4) {
                alert("해시태그는 최대 5개까지만 지정할 수 있습니다.");

                event.target.value = "";
            } else {
                if (inputVal[0] !== "#") {
                    setHashtags([...hashtags, "#" + inputVal]);
                } else {
                    setHashtags([...hashtags, inputVal]);
                }

                const auth = getAuth();

                const uid = auth.currentUser.uid;

                const firestore = getFirestore();

                try {
                    const docRef = doc(firestore, "user", uid);
                    updateDoc(docRef, {
                        "hashtags": hashtags.join(","),
                    });
                } catch (error) {
                    alert("서버와의 통신에 실패하였습니다");
                }

                event.target.value = "";
            }
        }
    };

    const removeHashtag = (indexToRemove) => {
        const filter = hashtags.filter((element, index) => index !== indexToRemove);
        setHashtags(filter);

        const auth = getAuth();

        const uid = auth.currentUser.uid;

        const firestore = getFirestore();

        try {
            const docRef = doc(firestore, "user", uid);
            updateDoc(docRef, {
                "hashtags": hashtags.join(","),
            });
        } catch (error) {
            alert("서버와의 통신에 실패하였습니다");
        }
    };

    const toolbarOptions = useMemo(() => ({
        toolbar: {
            container: [
                ["bold", "underline", "italic"],
                [
                    {"list": "ordered"},
                    {"list": "bullet"},
                    {"color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]},
                    {"background": []},
                ],
                ["image", "video", "link"],
            ],
            handlers: {image: imageHandler},
        },

        ImageResize: {
            parchment: Quill.import('parchment')
        }
    }), []);

    const onClick = async (props) => {
        setIsLoading(true);

        const auth = getAuth();
        const firestore = getFirestore();

        const timestamp = Date.now();

        if (auth.currentUser !== null) {
            await setDoc(doc(firestore, "board", timestamp.toString()), {
                "authorUid": auth.currentUser.uid,
                "content": content,
                "timestamp": timestamp,
                "hashtags": hashtags.join(","),
            });

            await setDoc(doc(firestore, "metadata", timestamp.toString()), {
                "authorUid": auth.currentUser.uid,
                "timestamp": timestamp,
                "numUps": 0,
                "numDowns": 0,
                "numViews": 0,
                "numComments": 0,
            });

            navigate("/");
        } else {
            alert("글 쓰기는 로그인하여야 가능합니다.")
        }

        setIsLoading(false);
    };

    return (
        <div className="Write">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        quillRef={quillRef}
                        toolbarOptions={toolbarOptions}
                        content={content}
                        setContent={setContent}
                        hashtags={hashtags}
                        addHashtag={addHashtag}
                        removeHashtag={removeHashtag}
                        onClick={onClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="Write-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function Hashtags(props) {
    return (
        <div className="Write-Hashtags">
            <h1>관심주제(해시태그)</h1>
            {
                <input
                    type="text"
                    onKeyUp={(e) => props.addHashtag(e)}
                    placeholder="해시태그를 입력해주세요"
                />
            }
            {
                <ul>
                    {props.hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <span className="Write-Hashtags-tag">{hashtag}</span>
                            <span className="Write-Hashtags-icon"
                                  onClick={() => props.removeHashtag(index)}>X</span>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function Submit(props) {
    return (
        <div className="Write-Submit">
            <button onClick={props.onClick}>
                글 쓰기
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className="Write-Body">
            <Logo/>
            <LeftSideBar/>
            <RightSideBar/>
            <Hashtags
                hashtags={props.hashtags}
                addHashtag={props.addHashtag}
                removeHashtag={props.removeHashtag}
            />
            <ReactQuill
                className="Write-Quill"
                ref={props.quillRef}
                modules={props.toolbarOptions}
                value={props.content}
                onChange={props.setContent}
            />
            <Submit onClick={props.onClick} quillRef={props.quillRef}/>
        </div>
    );
}