import "./AdminHome.css";
import React, {useEffect, useMemo, useState} from "react";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc,
} from "firebase/firestore";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import {Loading} from "../../desktop/loading/Loading";
import defaultAvatar from "../../assets/images/default_avatar.png";
import {useNavigate} from "react-router-dom";
import {ChatsCircle, DotsThreeOutline, Star, ThumbsDown, ThumbsUp, Trash} from "@phosphor-icons/react";
import {getAuth} from "firebase/auth";
import moment from "moment";
import parse from 'html-react-parser';
import logo from "../../assets/images/logo.png";
import {LeftSideBar} from "../../desktop/LeftSideBar";
import {RightSideBar} from "../../desktop/RightSideBar";

export function AdminHome() {
    const [isAdmin, setIsAdmin] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [lastVisible, setLastVisible] = useState(undefined);

    const checkIfAdmin = () => {
        const auth = getAuth();
        const firestore = getFirestore();

        if (auth.currentUser === null) {
            setIsAdmin(false);
        } else {
            const docRef = doc(firestore, "admin", auth.currentUser.uid);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setIsAdmin(true);
                }
            });
        }
    };

    const getNextDocuments = () => {
        setIsLoading(true);

        const firestore = getFirestore();

        let q;
        if (lastVisible === -1) {
            setIsLoading(false);
            return;
        } else if (lastVisible) {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10), startAfter(lastVisible));
        } else {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10));
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            setDocuments([...documents, ...newDocuments]);

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        setIsLoading(false);
    }

    useBottomScrollListener(getNextDocuments);

    useEffect(() => {
        setLastVisible(undefined);
        checkIfAdmin();
        getNextDocuments();
    }, []);

    const toolbarOptions = useMemo(() => ({
        toolbar: null
    }), []);

    return (
        <div className="AdminHome">
            {
                isAdmin ?
                    (isLoading ?
                    <Loading/> :
                    <Body
                        documents={documents}
                        toolbarOptions={toolbarOptions}
                        setIsLoading={setIsLoading}
                    />
                    ) :
                <NoPermission/>
            }
        </div>
    );
}

function Documents(props) {
    const documents = props.documents.map((item, i) => {
        return <Document
            document={item}
            toolbarOptions={props.toolbarOptions}
            setIsLoading={props.setIsLoading}/>;
    });

    return (
        <div className="AdminHome-Documents">
            {documents}
        </div>
    );
}

function Document(props) {
    return (
        <div className="AdminHome-Document">
            <Avatar
                authorUid={props.document["authorUid"]}
            />
            <Time timestamp={props.document["timestamp"]}/>
            <Nickname
                authorUid={props.document["authorUid"]}
            />
            <Delete
                timestamp={props.document["timestamp"]}
                authorUid={props.document["authorUid"]}
                setIsLoading={props.setIsLoading}
            />
            <ThumbnailContent
                content={props.document["content"]}
                toolbarOptions={props.toolbarOptions}
            />
            <ReadMore
                timestamp={props.document["timestamp"]}
                authorUid={props.document["authorUid"]}
            />
            <NumUps
                timestamp={props.document["timestamp"]}
                setIsLoading={props.setIsLoading}
            />
            <NumDowns
                timestamp={props.document["timestamp"]}
                setIsLoading={props.setIsLoading}
            />
            <NumComments
                timestamp={props.document["timestamp"]}
                authorUid={props.document["authorUid"]}
            />
            <Favorite
                timestamp={props.document["timestamp"]}
                setIsLoading={props.setIsLoading}
            />
        </div>
    );
}

function Avatar(props) {
    const navigate = useNavigate();

    const [authorAvatarUrl, setAuthorAvatarUrl] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("avatarUrl")) {
            setAuthorAvatarUrl(docSnap.data()["avatarUrl"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (authorAvatarUrl !== "") {
        return (
            <div className="AdminHome-Avatar">
                <img src={authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="AdminHome-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Time(props) {
    const endTimestamp = Date.now();

    const beginDate = moment(props.timestamp);
    const endDate = moment(endTimestamp);
    const diff = endDate.diff(beginDate);
    const diffDuration = moment.duration(diff);

    if (diffDuration.minutes() < 1) {
        return (
            <div className="AdminHome-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="AdminHome-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        );
    } else if (diffDuration.days() < 1) {
        return (
            <div className="AdminHome-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        );
    } else {
        return (
            <div className="AdminHome-Time">
                <p>{beginDate.year()}-{beginDate.month()}-{beginDate.day()}</p>
            </div>
        );
    }
}

function Nickname(props) {
    const navigate = useNavigate();

    const [nickname, setNickname] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("nickname")) {
            setNickname(docSnap.data()["nickname"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (nickname !== "") {
        return (
            <div className="AdminHome-Nickname">
                <button onClick={onClick}>
                    {nickname}
                </button>
            </div>
        );
    } else {
        return (
            <div className="AdminHome-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        );
    }
}

function Delete(props) {
    const [dropdown, setDropdown] = useState(false);

    const onDropdownClick = () => {
        if (dropdown === true) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onDeleteClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer === true) {
            props.setIsLoading(true);

            const firestore = getFirestore();

            await deleteDoc(doc(firestore, "board", props.timestamp.toString()));
            await deleteDoc(doc(firestore, "metadata", props.timestamp.toString()));

            props.setIsLoading(false);
        }
    }

    if (dropdown === true) {
        return (
            <div className="AdminHome-Delete">
                <botton className="AdminHome-Delete-Dropdown" onClick={onDropdownClick}>
                    <Trash color="red" size={24}/>
                </botton>
                <botton className="AdminHome-Delete-Delete" onClick={onDeleteClick}>삭제하기</botton>
            </div>
        );
    } else {
        return (
            <div className="AdminHome-Delete">
                <botton className="AdminHome-Delete-Dropdown" onClick={onDropdownClick}>
                    <Trash color="red" size={24}/>
                </botton>
            </div>
        );
    }
}

function ThumbnailContent(props) {
    const parsedContent = parse(props.content);

    if (Array.isArray(parsedContent) === false) {
        return (
            <div className="AdminHome-ThumbnailContent">
                {parsedContent}
            </div>
        );
    } else {
        if (recursiveLength(parsedContent) >= 10) {
            console.log(extractContent(parsedContent, 2, 0));

            return (
                <div className="AdminHome-ThumbnailContent">
                    {extractContent(parsedContent, 0, 0)}
                    {extractContent(parsedContent, 1, 0)}
                    {extractContent(parsedContent, 2, 0)}
                    {extractContent(parsedContent, 3, 0)}
                    {extractContent(parsedContent, 4, 0)}
                    {extractContent(parsedContent, 5, 0)}
                    {extractContent(parsedContent, 6, 0)}
                    {extractContent(parsedContent, 7, 0)}
                    {extractContent(parsedContent, 8, 0)}
                    {extractContent(parsedContent, 9, 0)}
                </div>
            );
        } else {
            return (
                <div className="AdminHome-ThumbnailContent">
                    {parsedContent}
                </div>
            );
        }
    }
}

function ReadMore(props) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/read", {state: {timestamp: props.timestamp, authorUid: props.authorUid}});
    }

    return (
        <div className="AdminHome-ReadMore">
            <button onClick={onClick}>
                <DotsThreeOutline size={24}/>
            </button>
        </div>
    );
}


function NumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });

        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const onClick = async () => {
            if (click === true) {
                props.setIsLoading(true);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });

                setNumUps(numUps - 1);
                setClick(false);

                props.setIsLoading(false);
            } else {
                props.setIsLoading(true);

                const upTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": upTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });

                setNumUps(numUps + 1);
                setClick(true);

                props.setIsLoading(false);
            }
        };

        if (click === true) {
            return (
                <div className="AdminHome-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="AdminHome-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="AdminHome-NumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [clickDown, setClickDown] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"]);
            }
        });

        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClickDown(true);
            }
        });

        const onClick = async () => {
            if (clickDown === true) {
                props.setIsLoading(true);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });

                setNumDowns(numDowns - 1);
                setClickDown(false);

                props.setIsLoading(false);
            } else {
                props.setIsLoading(true);

                const downTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": downTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });

                setNumDowns(numDowns + 1);
                setClickDown(true);

                props.setIsLoading(false);
            }
        };

        if (clickDown === true) {
            return (
                <div className="AdminHome-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="AdminHome-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="AdminHome-NumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments(props) {
    const navigate = useNavigate();

    const [numComments, setNumComments] = useState(0);

    const firestore = getFirestore();

    const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
    getDoc(summaryDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
            setNumComments(docSnap.data()["numComments"]);
        }
    });

    const onClick = async () => {
        navigate("/read", {state: {timestamp: props.timestamp, authorUid: props.authorUid}});
    };

    return (
        <div className="AdminHome-NumComments">
            <button onClick={onClick}>
                <ChatsCircle size={24}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function Favorite(props) {
    const navigate = useNavigate();

    const [clickFavorite, setClickFavorite] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const favoriteDocRef = doc(firestore, "user", auth.currentUser.uid, "favorite", props.timestamp.toString());
        getDoc(favoriteDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClickFavorite(true);
            }
        });

        const onClick = async () => {
            if (clickFavorite === true) {
                props.setIsLoading(true);

                await deleteDoc(favoriteDocRef);

                setClickFavorite(false);

                props.setIsLoading(false);
            } else {
                props.setIsLoading(true);

                const favoriteTimestamp = Date.now();

                await setDoc(favoriteDocRef, {
                    "timestamp": props.timestamp,
                    "uid": auth.currentUser.uid,
                    "favoriteTimestamp": favoriteTimestamp,
                });

                setClickFavorite(true);

                props.setIsLoading(false);
            }
        };

        if (clickFavorite === true) {
            return (
                <div className="AdminHome-Favorite">
                    <button onClick={onClick}>
                        <Star color="#e7fbff" size={24}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="AdminHome-Favorite">
                    <button onClick={onClick}>
                        <Star size={24}/>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="AdminHome-Favorite">
                <button onClick={onClick}>
                    <Star size={24}/>
                </button>
            </div>
        );
    }
}

function Body(props) {
    return (
        <div className="AdminHome-Body">
            <LeftSideBar/>
            <Documents
                documents={props.documents}
                toolbarOptions={props.toolbarOptions}
                setIsLoading={props.setIsLoading}
            />
            <RightSideBar/>
        </div>
    );
}

function Logo() {
    return (
        <div className="AdminHome-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function NoPermission(props) {
    return (
        <div className="AdminHome-NoPermission">
            <Logo/>
            <h1>접근 권한이 없습니다</h1>
        </div>
    );
}

function recursiveLength(content) {
    if (Array.isArray(content) === true) {
        let length = 0;
        for (let child of content) {
            if (Array.isArray(child.props.children) === true) {
                length += recursiveLength(child);
            } else {
                length += 1;
            }
        }
        return length;
    } else if (content.hasOwnProperty("props") === true &&
        content.props.hasOwnProperty("children") === true &&
        Array.isArray(content.props.children) === true) {
        let length = 0;
        for (let child of content.props.children) {
            if (Array.isArray(child.props.children) === true) {
                length += recursiveLength(child);
            } else {
                length += 1;
            }
        }
        return length;
    } else {
        return 1;
    }
}

function  extractContent(content, pos, accum) {
    if (Array.isArray(content) === true) {
        for (let i = 0; i < content.length; i++) {
            const child = content[i];

            if (accum <= pos && accum + recursiveLength(child) > pos) {
                return extractContent(child, pos, accum);
            } else if (accum > pos) {
                return;
            } else {
                accum += recursiveLength(child);
            }
        }
    } else if (content.hasOwnProperty("props") === true &&
        content.props.hasOwnProperty("children") === true &&
        Array.isArray(content.props.children) === true) {
        for (let i = 0; i < content.props.children.length; i++) {
            const child = content.props.children[i];

            if (accum <= pos && accum + recursiveLength(child) > pos) {
                return extractContent(child, pos, accum);
            } else if (accum > pos) {
                return;
            } else {
                accum += recursiveLength(child);
            }
        }
    } else {
        if (pos === accum) {
            return content;
        }
    }
}