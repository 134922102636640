import {useLocation, useNavigate} from "react-router-dom";
import {Loading} from "../loading/Loading";
import React, {useEffect, useState} from "react";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc
} from "firebase/firestore";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import defaultAvatar from "../../assets/images/default_avatar.png";
import {getAuth} from "firebase/auth";
import {ShareFat, ThumbsDown, ThumbsUp, Warning} from "@phosphor-icons/react";
import "./Reply.css";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import moment from "moment/moment";

export function Reply() {
    const {
        state: {timestamp, commentTimestamp, commentAuthorUid}
    } = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState("");
    const [replies, setReplies] = useState([]);
    const [lastVisible, setLastVisible] = useState(undefined);

    const firestore = getFirestore();

    const getNextReplies = () => {
        setIsLoading(true);

        const firestore = getFirestore();

        let q;
        if (lastVisible === -1) {
            setIsLoading(false);
            return;
        } else if (lastVisible) {
            q = query(collection(firestore, "board", timestamp.toString(), "comment", commentTimestamp.toString(), "reply"), orderBy("timestamp", "desc"), limit(10), startAfter(lastVisible));
        } else {
            q = query(collection(firestore, "board", timestamp.toString(), "comment", commentTimestamp.toString(), "reply"), orderBy("timestamp", "desc"), limit(10));
        }

        onSnapshot(q, async (snapshot) => {
            const newReplies = snapshot.docs.map((elem) => elem.data());

            setReplies([...replies, ...newReplies]);

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        setIsLoading(false);
    };

    useBottomScrollListener(getNextReplies);

    useEffect(() => {
        setIsLoading(true);

        const docRef = doc(firestore, "board", timestamp.toString(), "comment", commentTimestamp.toString());
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("content")) {
                setContent(docSnap.data()["content"]);
            }
        });

        setLastVisible(undefined);
        getNextReplies();

        setIsLoading(false);
    }, []);

    return (
        <div className="Reply">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        timestamp={timestamp}
                        commentTimestamp={commentTimestamp}
                        commentAuthorUid={commentAuthorUid}
                        content={content}
                        replies={replies}
                        setIsLoading={setIsLoading}
                    />
            }
        </div>
    );
}

function Comment(props) {
    return (
        <div className="Reply-Comment">
            <Avatar
                authorUid={props.commentAuthorUid}
            />
            <Time timestamp={props.commentTimestamp}/>
            <Nickname
                authorUid={props.commentAuthorUid}
            />
            <Report
                timestamp={props.timestamp}
                commentTimestamp={props.commentTimestamp}
                commentAuthorUid={props.commentAuthorUid}
            />
            <CommentBody content={props.content}/>
            <NumUps timestamp={props.timestamp} commentTimestamp={props.commentTimestamp}/>
            <NumDowns timestamp={props.timestamp} commentTimestamp={props.commentTimestamp}/>
            <NumReplies timestamp={props.timestamp} commentTimestamp={props.commentTimestamp}/>
        </div>
    );
}

function Avatar(props) {
    const navigate = useNavigate();

    const [authorAvatarUrl, setAuthorAvatarUrl] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("avatarUrl")) {
            setAuthorAvatarUrl(docSnap.data()["avatarUrl"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (authorAvatarUrl !== "") {
        return (
            <div className="Reply-Avatar">
                <img src={authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Time(props) {
    const endTimestamp = Date.now();

    const beginDate = moment(props.timestamp);
    const endDate = moment(endTimestamp);
    const diff = endDate.diff(beginDate);
    const diffDuration = moment.duration(diff);

    if (diffDuration.minutes() < 1) {
        return (
            <div className="Reply-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="Reply-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        );
    } else if (diffDuration.days() < 1) {
        return (
            <div className="Reply-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        );
    } else {
        return (
            <div className="Reply-Time">
                <p>{beginDate.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Nickname(props) {
    const navigate = useNavigate();

    const [nickname, setNickname] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("nickname")) {
            setNickname(docSnap.data()["nickname"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (nickname !== "") {
        return (
            <div className="Reply-Nickname">
                <button onClick={onClick}>
                    {nickname}
                </button>
            </div>
        );
    } else {
        return (
            <div className="Reply-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        );
    }
}

function Report(props) {
    const navigate = useNavigate();

    const auth = getAuth();

    const [dropdown, setDropdown] = useState(false);

    const onDropdownClick = () => {
        if (dropdown === true) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onReportClick = () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.commentAuthorUid) {
                navigate("/report", {
                    state: {
                        postType: "comment",
                        timestamp: props.timestamp,
                        commentTimestamp: props.commentTimestamp
                    }
                });
            } else {
                alert("자기가 쓴 댓글은 신고할 수 없습니다.");
            }
        } else {
            navigate("/login");
        }
    }

    if (dropdown === true) {
        return (
            <div className="Reply-Report">
                <botton className="Reply-Report-Dropdown" onClick={onDropdownClick}>
                    <Warning color="red" size={24}/>
                </botton>
                <botton className="Reply-Report-Report" onClick={onReportClick}>신고하기</botton>
            </div>
        );
    } else {
        return (
            <div className="Reply-Report">
                <botton className="Home-Report-Dropdown" onClick={onDropdownClick}>
                    <Warning color="red" size={24}/>
                </botton>
            </div>
        );
    }
}

function CommentBody(props) {
    return (
        <div className="Reply-CommentBody">
            <p>
                {props.content}
            </p>
        </div>
    );
}

function NumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });
            } else {
                setNumUps(numUps + 1);
                setClick(true);

                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Reply-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Reply-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Reply-NumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [clickDown, setClickDown] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClickDown(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());

        const onClick = async () => {
            if (clickDown === true) {
                setNumDowns(numDowns - 1);
                setClickDown(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });
            } else {
                setNumDowns(numDowns + 1);
                setClickDown(true);

                const downTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": downTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });
            }
        };

        if (clickDown === true) {
            return (
                <div className="Reply-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Reply-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Reply-NumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumReplies(props) {
    const navigate = useNavigate();

    const [numReplies, setNumReplies] = useState(0);

    const firestore = getFirestore();

    const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
    getDoc(summaryDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numReplies")) {
            setNumReplies(docSnap.data()["numReplies"]);
        }
    });

    return (
        <div className="Reply-NumReplies">
            <button>
                <ShareFat size={24}/>
                <p>{numReplies}</p>
            </button>
        </div>
    );
}

function Replies(props) {
    const replies = props.replies.map((item, i) => {
        return <Reply_ reply={item}/>;
    });

    return (
        <div className="Reply-Replies">
            {replies}
        </div>
    );
}

function Reply_(props) {
    return (
        <div className="Reply-Reply">
            <Avatar
                authorUid={props.reply["replyAuthorUid"]}
            />
            <Time timestamp={props.reply["replyTimestamp"]}/>
            <Nickname
                authorUid={props.reply["replyAuthorUid"]}
            />
            <ReplyBody content={props.reply["content"]}/>
            <ReplyNumUps
                timestamp={props.reply["timestamp"]}
                commentTimestamp={props.reply["commentTimestamp"]}
                replyTimestamp={props.reply["replyTimestamp"]}
            />
            <ReplyNumDowns
                timestamp={props.reply["timestamp"]}
                commentTimestamp={props.reply["commentTimestamp"]}
                replyTimestamp={props.reply["replyTimestamp"]}
            />
        </div>
    );
}

function ReplyNumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", props.replyTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", props.replyTimestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", props.replyTimestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });
            } else {
                setNumUps(numUps + 1);
                setClick(true);

                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Reply-ReplyNumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Reply-ReplyNumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Reply-ReplyNumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function ReplyNumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", props.replyTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numUps"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", props.replyTimestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", props.replyTimestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumDowns(numDowns - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });
            } else {
                setNumDowns(numDowns + 1);
                setClick(true);

                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Reply-ReplyNumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Reply-ReplyNumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Reply-ReplyNumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function ReplyBody(props) {
    return (
        <div className="Reply-ReplyBody">
            <p>
                {props.content}
            </p>
        </div>
    );
}

function ReplyInput(props) {
    const [content, setContent] = useState("");

    const onContentChange = (e) => {
        const {target: {name, value}} = e;

        setContent(value);
    };

    return <div className="Reply-ReplyInput">
        <form>
            <input
                className="Reply-ReplyInput-Content"
                type="text"
                placeholder="대댓글"
                required
                value={content}
                onChange={onContentChange}/>
        </form>
        <ReplySubmit
            timestamp={props.timestamp}
            commentTimestamp={props.commentTimestamp}
            content={content}
            setContent={setContent}
            setIsLoading={props.setIsLoading}
        />
    </div>
}

function ReplySubmit(props) {
    const onClick = async () => {
        if (props.content !== "") {
            props.setIsLoading(true);

            const auth = getAuth();
            const firestore = getFirestore();

            const replyTimestamp = Date.now();

            if (auth.currentUser !== null) {
                await updateDoc(doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString()), {
                    "numReplies": increment(1),
                });

                await setDoc(doc(firestore, "board", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", replyTimestamp.toString()), {
                    "replyAuthorUid": auth.currentUser.uid,
                    "content": props.content,
                    "timestamp": props.timestamp,
                    "commentTimestamp": props.commentTimestamp,
                    "replyTimestamp": replyTimestamp,
                    "numUps": 0,
                    "numDowns": 0,
                });

                await setDoc(doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "reply", replyTimestamp.toString()), {
                    "authorUid": auth.currentUser.uid,
                    "timestamp": replyTimestamp,
                });
            } else {
                alert("대댓글 쓰기는 로그인하여야 가능합니다.")
            }

            props.setContent("");
            props.setIsLoading(false);
        }
    };

    return <div className="Reply-ReplySubmit">
        <button onClick={onClick}>
            대댓글 쓰기
        </button>
    </div>
}

function CommentStart() {
    return (
        <div className="Reply-CommentStart">
            <p>
                ------------------댓글 화면------------------
            </p>
        </div>
    );
}

function RepliesStart() {
    return (
        <div className="Reply-RepliesStart">
            <p>
                ----------------대댓글 시작----------------
            </p>
        </div>
    );
}

function Body(props) {
    return (
        <div className="Reply-Body">
            <LeftSideBar/>
            <CommentStart/>
            <Comment
                timestamp={props.timestamp}
                commentTimestamp={props.commentTimestamp}
                commentAuthorUid={props.commentAuthorUid}
                content={props.content}
                numUps={props.numUps}
                numDowns={props.numDowns}
            />
            <ReplyInput
                timestamp={props.timestamp}
                commentTimestamp={props.commentTimestamp}
                commentAuthorUid={props.commentAuthorUid}
                setIsLoading={props.setIsLoading}
            />
            <RepliesStart/>
            <Replies timestamp={props.timestamp} replies={props.replies}/>
            <RightSideBar/>
        </div>
    );
}
