import React, {useEffect, useState} from "react";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {
    ChatsCircle,
    Compass,
    Gear,
    HandFist,
    House,
    MagnifyingGlass,
    Notification,
    Pencil,
    Person,
    SignIn,
    SignOut,
    Smiley
} from "@phosphor-icons/react";
import {Link, useNavigate} from "react-router-dom";
import {Menu, MenuItem, Sidebar, sidebarClasses, SubMenu} from "react-pro-sidebar";
import "./LeftSideBar.css";
import "./SideBar.css";
import {getAuth, signOut} from "firebase/auth";
import {doc, getDoc, getFirestore} from "firebase/firestore";

export function LeftSideBar() {
    const [isAdmin, setIsAdmin] = useState(false);

    const auth = getAuth();

    const checkIfAdmin = () => {
        const auth = getAuth();
        const firestore = getFirestore();

        if (auth.currentUser === null) {
            setIsAdmin(false);
        } else {
            const docRef = doc(firestore, "admin", auth.currentUser.uid);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setIsAdmin(true);
                }
            });
        }
    };

    useEffect(() => {
        checkIfAdmin();
    }, []);

    if (auth.currentUser !== null) {
        if (isAdmin) {
            return (
                <div className="LeftSideBar">
                    <Sidebar rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: '#e7fbff',
                            color: 'white',
                        },
                    }}>
                        <Menu>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/"/>} icon={<House size={24}/>}>
                                홈
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/search"/>}
                                      icon={<MagnifyingGlass size={24}/>}>
                                검색
                            </MenuItem>
                            <SubMenu className="SideBar-subMenu" label="관심" icon={<Compass/>}>
                                <MenuItem className="SideBar-menuItem" component={<Link to="/interest/subject"/>}
                                          icon={<ChatsCircle size={24}/>}>
                                    주제
                                </MenuItem>
                                <MenuItem className="SideBar-menuItem" component={<Link to="/interest/author"/>}
                                          icon={<Person size={24}/>}>
                                    작가
                                </MenuItem>
                            </SubMenu>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/notification"/>}
                                      icon={<Notification size={24}/>}>
                                알림
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/write"/>}
                                      icon={<Pencil size={24}/>}>
                                글 쓰기
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/setting"/>}
                                      icon={<Gear size={24}/>}>
                                설정
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/profile"/>}
                                      icon={<Smiley size={24}/>}>
                                프로필
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/admin"/>}
                                      icon={<HandFist size={24}/>}>
                                관리자
                            </MenuItem>
                            <SignInOrOut/>
                        </Menu>
                    </Sidebar>
                </div>
            );
        } else {
            return (
                <div className="LeftSideBar">
                    <Sidebar rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: '#e7fbff',
                            color: 'white',
                        },
                    }}>
                        <Menu>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/"/>} icon={<House size={24}/>}>
                                홈
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/search"/>}
                                      icon={<MagnifyingGlass size={24}/>}>
                                검색
                            </MenuItem>
                            <SubMenu className="SideBar-subMenu" label="관심" icon={<Compass/>}>
                                <MenuItem className="SideBar-menuItem" component={<Link to="/interest/subject"/>}
                                          icon={<ChatsCircle size={24}/>}>
                                    주제
                                </MenuItem>
                                <MenuItem className="SideBar-menuItem" component={<Link to="/interest/author"/>}
                                          icon={<Person size={24}/>}>
                                    작가
                                </MenuItem>
                            </SubMenu>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/notification"/>}
                                      icon={<Notification size={24}/>}>
                                알림
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/write"/>}
                                      icon={<Pencil size={24}/>}>
                                글 쓰기
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/setting"/>}
                                      icon={<Gear size={24}/>}>
                                설정
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/profile"/>}
                                      icon={<Smiley size={24}/>}>
                                프로필
                            </MenuItem>
                            <SignInOrOut/>
                        </Menu>
                    </Sidebar>
                </div>
            );
        }
    } else {
        return (
            <div className="LeftSideBar">
                <Sidebar rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                        backgroundColor: '#e7fbff',
                        color: 'white',
                    },
                }}>
                    <Menu>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/"/>} icon={<House size={24}/>}>
                            홈
                        </MenuItem>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/search"/>}
                                  icon={<MagnifyingGlass size={24}/>}>
                            검색
                        </MenuItem>
                        <SubMenu className="SideBar-subMenu" label="관심" icon={<Compass/>}>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>}
                                      icon={<ChatsCircle size={24}/>}>
                                주제
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>}
                                      icon={<Person size={24}/>}>
                                작가
                            </MenuItem>
                        </SubMenu>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>}
                                  icon={<Notification size={24}/>}>
                            알림
                        </MenuItem>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>}
                                  icon={<Pencil size={24}/>}>
                            글 쓰기
                        </MenuItem>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>}
                                  icon={<Smiley size={24}/>}>
                            프로필
                        </MenuItem>
                        <SignInOrOut/>
                    </Menu>
                </Sidebar>
            </div>
        );
    }
}

function SignInOrOut() {
    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = () => {
        signOut(auth).then(() => {
            navigate("/");
        }).catch((error) => {
        });
    }

    if (auth.currentUser == null) {
        return (
            <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>} icon={<SignIn size={24}/>}>
                로그인
            </MenuItem>
        )
    } else {
        return (
            <MenuItem className="SideBar-menuItem" onClick={onClick} icon={<SignOut size={24}/>}>
                로그아웃
            </MenuItem>
        )
    }
}
