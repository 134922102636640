import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Login} from "./desktop/login/Login";
import {EmailVerification} from "./desktop/email_verification/EmailVerification";
import {PasswordReset} from "./desktop/password_reset/PasswordReset";
import {Write} from "./desktop/write/Write";
import {AdminHome} from "./admin/home/AdminHome";
import {Home} from "./desktop/home/Home";
import {Profile} from "./desktop/profile/Profile";
import {Eula} from "./desktop/eula/Eula";
import {SignUp} from "./desktop/sign_up/SignUp";
import {Setting} from "./desktop/setting/Setting";
import {isMobile} from 'react-device-detect';
import {MobileLogin} from "./mobile/login/MobileLogin";
import {MobileEula} from "./mobile/eula/MobileEula";
import {MobileSignUp} from "./mobile/sign_up/MobileSignUp";
import {MobileEmailVerification} from "./mobile/email_verification/MobileEmailVerification";
import {MobilePasswordReset} from "./mobile/password_reset/MobilePasswordReset";
import {MobileMenu} from "./mobile/menu/MobileMenu";
import {Read} from "./desktop/read/Read";
import {Edit} from "./desktop/edit/Edit";
import {Report} from "./desktop/report/Report";
import {Reply} from "./desktop/reply/Reply";

function App() {
    if (isMobile === false) {
        return (
            <BrowserRouter>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/email_verification" element={<EmailVerification/>}/>
                        <Route path="/password_reset" element={<PasswordReset/>}/>
                        <Route path="/eula" element={<Eula/>}/>
                        <Route path="/sign_up" element={<SignUp/>}/>
                        <Route path="/read" element={<Read/>}/>
                        <Route path="/write" element={<Write/>}/>
                        <Route path="/reply" element={<Reply/>}/>
                        <Route path="/edit" element={<Edit/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/setting" element={<Setting/>}/>
                        <Route path="/report" element={<Report/>}/>
                        <Route path="/admin" element={<AdminHome/>}/>
                    </Routes>
                </div>
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <div className="App">
                    <Routes>
                        <Route path="/menu" element={<MobileMenu/>}/>
                        <Route path="/login" element={<MobileLogin/>}/>
                        <Route path="/eula" element={<MobileEula/>}/>
                        <Route path="/email_verification" element={<MobileEmailVerification/>}/>
                        <Route path="/password_reset" element={<MobilePasswordReset/>}/>
                        <Route path="/sign_up" element={<MobileSignUp/>}/>
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
