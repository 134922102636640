import "./Home.css";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import React, {useEffect, useMemo, useState} from "react";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc,
} from "firebase/firestore";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import {Loading} from "../loading/Loading";
import defaultAvatar from "../../assets/images/default_avatar.png";
import {useNavigate} from "react-router-dom";
import {ChatsCircle, DotsThreeOutline, Star, ThumbsDown, ThumbsUp, Warning} from "@phosphor-icons/react";
import {getAuth} from "firebase/auth";
import moment from "moment";
import parse from 'html-react-parser';

export function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [lastVisible, setLastVisible] = useState(undefined);

    const getNextDocuments = () => {
        setIsLoading(true);

        const firestore = getFirestore();

        let q;
        if (lastVisible === -1) {
            setIsLoading(false);
            return;
        } else if (lastVisible) {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10), startAfter(lastVisible));
        } else {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10));
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            setDocuments([...documents, ...newDocuments]);

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        setIsLoading(false);
    };

    useBottomScrollListener(getNextDocuments);

    useEffect(() => {
        setLastVisible(undefined);
        getNextDocuments();
    }, []);

    const toolbarOptions = useMemo(() => ({
        toolbar: null,
    }), []);

    return (
        <div className="Home">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        documents={documents}
                        toolbarOptions={toolbarOptions}
                    />
            }
        </div>
    );
}

function Documents(props) {
    const documents = props.documents.map((item, i) => {
        return <Document
            document={item}/>;
    });

    return (
        <div className="Home-Documents">
            {documents}
        </div>
    );
}

function Document(props) {
    return (
        <div className="Home-Document">
            <Avatar
                authorUid={props.document["authorUid"]}
            />
            <Time timestamp={props.document["timestamp"]}/>
            <Nickname
                authorUid={props.document["authorUid"]}
            />
            <Report
                timestamp={props.document["timestamp"]}
                authorUid={props.document["authorUid"]}
            />
            <ThumbnailContent
                timestamp={props.document["timestamp"]}
                authorUid={props.document["authorUid"]}
                content={props.document["content"]}
            />
            <Hashtags
                hashtags={props.document["hashtags"]}
            />
            <NumUps
                timestamp={props.document["timestamp"]}
            />
            <NumDowns
                timestamp={props.document["timestamp"]}
            />
            <NumComments
                timestamp={props.document["timestamp"]}
                authorUid={props.document["authorUid"]}
            />
            <Favorite
                timestamp={props.document["timestamp"]}
            />
        </div>
    );
}

function Avatar(props) {
    const navigate = useNavigate();

    const [authorAvatarUrl, setAuthorAvatarUrl] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("avatarUrl")) {
            setAuthorAvatarUrl(docSnap.data()["avatarUrl"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (authorAvatarUrl !== "") {
        return (
            <div className="Home-Avatar">
                <img src={authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Home-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Time(props) {
    const endTimestamp = Date.now();

    const beginDate = moment(props.timestamp);
    const endDate = moment(endTimestamp);
    const diff = endDate.diff(beginDate);
    const diffDuration = moment.duration(diff);

    if (diffDuration.minutes() < 1) {
        return (
            <div className="Home-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="Home-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        );
    } else if (diffDuration.days() < 1) {
        return (
            <div className="Home-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        );
    } else {
        return (
            <div className="Home-Time">
                <p>{beginDate.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Nickname(props) {
    const navigate = useNavigate();

    const [nickname, setNickname] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("nickname")) {
            setNickname(docSnap.data()["nickname"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (nickname !== "") {
        return (
            <div className="Home-Nickname">
                <button onClick={onClick}>
                    {nickname}
                </button>
            </div>
        );
    } else {
        return (
            <div className="Home-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        );
    }
}

function Report(props) {
    const navigate = useNavigate();

    const auth = getAuth();

    const [dropdown, setDropdown] = useState(false);

    const onDropdownClick = () => {
        if (dropdown === true) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onReportClick = () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.authorUid) {
                navigate("/report", {state: {postType: "post", timestamp: props.timestamp}});
            } else {
                alert("자기가 쓴 글은 신고할 수 없습니다.");
            }
        } else {
            navigate("/login");
        }
    }

    if (dropdown === true) {
        return (
            <div className="Home-Report">
                <botton className="Home-Report-Dropdown" onClick={onDropdownClick}>
                    <Warning color="red" size={24}/>
                </botton>
                <botton className="Home-Report-Report" onClick={onReportClick}>신고하기</botton>
            </div>
        );
    } else {
        return (
            <div className="Home-Report">
                <botton className="Home-Report-Dropdown" onClick={onDropdownClick}>
                    <Warning color="red" size={24}/>
                </botton>
            </div>
        );
    }
}

function ThumbnailContent(props) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/read", {state: {timestamp: props.timestamp, authorUid: props.authorUid}});
    }

    const parsedContent = parse(props.content);

    if (Array.isArray(parsedContent) === false) {
        return (
            <div className="Home-ThumbnailContent">
                <button onClick={onClick}>
                    {parsedContent}
                </button>
            </div>
        );
    } else {
        if (recursiveLength(parsedContent) >= 10) {
            console.log(extractContent(parsedContent, 2, 0));

            return (
                <div className="Home-ThumbnailContent">
                    <button onClick={onClick}>
                        {extractContent(parsedContent, 0, 0)}
                        {extractContent(parsedContent, 1, 0)}
                        {extractContent(parsedContent, 2, 0)}
                        {extractContent(parsedContent, 3, 0)}
                        {extractContent(parsedContent, 4, 0)}
                        {extractContent(parsedContent, 5, 0)}
                        {extractContent(parsedContent, 6, 0)}
                        {extractContent(parsedContent, 7, 0)}
                        {extractContent(parsedContent, 8, 0)}
                        {extractContent(parsedContent, 9, 0)}
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Home-ThumbnailContent">
                    <button onClick={onClick}>
                        {parsedContent}
                    </button>
                </div>
            );
        }
    }
}

function Hashtags(props) {
    const hashtags = props.hashtags.split(",");

    return (
        <div className="Home-Hashtags">
            {
                <ul>
                    {hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <span className="Home-Hashtags-tag">{hashtag}</span>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

function NumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });
            } else {
                setNumUps(numUps + 1);
                setClick(true);

                const upTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": upTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Home-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Home-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Home-NumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [clickDown, setClickDown] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClickDown(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());

        const onClick = async () => {
            if (clickDown === true) {
                setNumDowns(numDowns - 1);
                setClickDown(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });
            } else {
                setNumDowns(numDowns + 1);
                setClickDown(true);

                const downTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": downTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });
            }
        };

        if (clickDown === true) {
            return (
                <div className="Home-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Home-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Home-NumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments(props) {
    const navigate = useNavigate();

    const [numComments, setNumComments] = useState(0);

    const firestore = getFirestore();

    const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
    getDoc(summaryDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
            setNumComments(docSnap.data()["numComments"]);
        }
    });

    const onClick = async () => {
        navigate("/read", {state: {timestamp: props.timestamp, authorUid: props.authorUid}});
    };

    return (
        <div className="Home-NumComments">
            <button onClick={onClick}>
                <ChatsCircle size={24}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function Favorite(props) {
    const navigate = useNavigate();

    const [clickFavorite, setClickFavorite] = useState(false);

    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser !== null) {
            const firestore = getFirestore();

            const favoriteDocRef = doc(firestore, "user", auth.currentUser.uid, "favorite", props.timestamp.toString());
            getDoc(favoriteDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setClickFavorite(true);
                }
            });
        }
    }, []);

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const favoriteDocRef = doc(firestore, "user", auth.currentUser.uid, "favorite", props.timestamp.toString());

        const onClick = async () => {
            if (clickFavorite === true) {
                setClickFavorite(false);

                await deleteDoc(favoriteDocRef);
            } else {
                setClickFavorite(true);

                const favoriteTimestamp = Date.now();

                await setDoc(favoriteDocRef, {
                    "timestamp": props.timestamp,
                    "uid": auth.currentUser.uid,
                    "favoriteTimestamp": favoriteTimestamp,
                });
            }
        };

        if (clickFavorite === true) {
            return (
                <div className="Home-Favorite">
                    <button onClick={onClick}>
                        <Star color="#e7fbff" size={24}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Home-Favorite">
                    <button onClick={onClick}>
                        <Star size={24}/>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Home-Favorite">
                <button onClick={onClick}>
                    <Star size={24}/>
                </button>
            </div>
        );
    }
}

function Body(props) {
    return (
        <div className="Home-Body">
            <LeftSideBar/>
            <Documents
                documents={props.documents}
            />
            <RightSideBar/>
        </div>
    );
}

function recursiveLength(content) {
    if (Array.isArray(content) === true) {
        let length = 0;
        for (let child of content) {
            if (Array.isArray(child.props.children) === true) {
                length += recursiveLength(child);
            } else {
                length += 1;
            }
        }
        return length;
    } else if (content.hasOwnProperty("props") === true &&
        content.props.hasOwnProperty("children") === true &&
        Array.isArray(content.props.children) === true) {
        let length = 0;
        for (let child of content.props.children) {
            if (child.hasOwnProperty("props") === true &&
                child.props.hasOwnProperty("children") === true &&
                Array.isArray(child.props.children) === true) {
                length += recursiveLength(child);
            } else {
                length += 1;
            }
        }
        return length;
    } else {
        return 1;
    }
}

function extractContent(content, pos, accum) {
    if (Array.isArray(content) === true) {
        for (let i = 0; i < content.length; i++) {
            const child = content[i];

            if (accum <= pos && accum + recursiveLength(child) > pos) {
                return extractContent(child, pos, accum);
            } else if (accum > pos) {
                return;
            } else {
                accum += recursiveLength(child);
            }
        }
    } else if (content.hasOwnProperty("props") === true &&
        content.props.hasOwnProperty("children") === true &&
        Array.isArray(content.props.children) === true) {
        for (let i = 0; i < content.props.children.length; i++) {
            const child = content.props.children[i];

            if (accum <= pos && accum + recursiveLength(child) > pos) {
                return extractContent(child, pos, accum);
            } else if (accum > pos) {
                return;
            } else {
                accum += recursiveLength(child);
            }
        }
    } else {
        if (pos === accum) {
            return content;
        }
    }
}
