import {useLocation, useNavigate} from "react-router-dom";
import {Loading} from "../loading/Loading";
import React, {useEffect, useMemo, useState} from "react";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc
} from "firebase/firestore";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import defaultAvatar from "../../assets/images/default_avatar.png";
import {getAuth} from "firebase/auth";
import {
    ChatsCircle,
    DotsThreeOutline,
    Pencil,
    ShareFat,
    Star,
    ThumbsDown,
    ThumbsUp,
    Warning
} from "@phosphor-icons/react";
import "./Read.css";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import moment from "moment/moment";
import parse from "html-react-parser";

export function Read() {
    const location = useLocation();

    const [timestamp, setTimestamp] = useState(location.state.timestamp);
    const [authorUid, setAuthorUid] = useState(location.state.authorUid);

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState("");
    const [hashtags, setHashtags] = useState("");
    const [comments, setComments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [commentLastVisible, setCommentLastVisible] = useState(undefined);
    const [documentsLastVisible, setDocumentsLastVisible] = useState(undefined);

    const firestore = getFirestore();

    const getNextComments = () => {
        setIsLoading(true);

        const firestore = getFirestore();

        let q;
        if (commentLastVisible === -1) {
            setIsLoading(false);
            return;
        } else if (commentLastVisible) {
            q = query(collection(firestore, "board", timestamp.toString(), "comment"), orderBy("timestamp", "desc"), limit(10), startAfter(commentLastVisible));
        } else {
            q = query(collection(firestore, "board", timestamp.toString(), "comment"), orderBy("timestamp", "desc"), limit(10));
        }

        onSnapshot(q, async (snapshot) => {
            const newComments = snapshot.docs.map((elem) => elem.data());

            setComments([...comments, ...newComments]);

            if (snapshot.docs.length === 0) {
                setCommentLastVisible(-1);
            } else {
                setCommentLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        setIsLoading(false);
    };

    const getNextDocuments = () => {
        setIsLoading(true);

        const firestore = getFirestore();

        let q;
        if (documentsLastVisible === -1) {
            setIsLoading(false);
            return;
        } else if (documentsLastVisible) {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10), startAfter(documentsLastVisible));
        } else {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10));
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data());

            setDocuments([...documents, ...newDocuments]);

            if (snapshot.docs.length === 0) {
                setDocumentsLastVisible(-1);
            } else {
                setDocumentsLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        setIsLoading(false);
    };

    useBottomScrollListener(getNextDocuments);

    useEffect(() => {
        setIsLoading(true);

        const docRef = doc(firestore, "board", timestamp.toString());
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("content")) {
                setContent(docSnap.data()["content"]);
            }

            if (docSnap.exists() && docSnap.data().hasOwnProperty("hashtags")) {
                setHashtags(docSnap.data()["hashtags"]);
            }
        });

        setCommentLastVisible(undefined);
        setDocumentsLastVisible(undefined);

        getNextComments();
        getNextDocuments();

        setIsLoading(false);
    }, [timestamp]);

    const toolbarOptions = useMemo(() => ({
        toolbar: null
    }), []);

    return (
        <div className="Read">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        timestamp={timestamp}
                        authorUid={authorUid}
                        content={content}
                        hashtags={hashtags}
                        toolbarOptions={toolbarOptions}
                        comments={comments}
                        setComments={setComments}
                        setCommentLastVisible={setCommentLastVisible}
                        getNextComments={getNextComments}
                        documents={documents}
                        setDocuments={setDocuments}
                        setDocumentsLastVisible={setDocumentsLastVisible}
                        getNextDocuments={getNextDocuments}
                        setIsLoading={setIsLoading}
                        setTimestamp={setTimestamp}
                        setAuthorUid={setAuthorUid}
                    />
            }
        </div>
    );
}

function Content(props) {
    const parsedContent = parse(props.content);

    return (
        <div className="Read-Content">
            {parsedContent}
        </div>
    );
}

function Hashtags(props) {
    const hashtags = props.hashtags.split(",");

    return (
        <div className="Read-Hashtags">
            {
                <ul>
                    {hashtags.map((hashtag, index) => (
                        <li key={index}>
                            <span className="Read-Hashtags-tag">{hashtag}</span>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}


function Document(props) {
    return (
        <div className="Read-Document">
            <Avatar
                authorUid={props.authorUid}
            />
            <Time timestamp={props.timestamp}/>
            <Nickname
                authorUid={props.authorUid}
            />
            <Dropdown
                timestamp={props.timestamp}
                authorUid={props.authorUid}
            />
            <Content
                timestamp={props.timestamp}
                authorUid={props.authorUid}
                content={props.content}
            />
            <Hashtags
                hashtags={props.hashtags}
            />
            <NumUps timestamp={props.timestamp}/>
            <NumDowns timestamp={props.timestamp}/>
            <NumComments
                timestamp={props.timestamp}
                authorUid={props.authorUid}
                setTimestamp={props.setTimestamp}
                setAuthorUid={props.setAuthorUid}
                setCommentLastVisible={props.setCommentLastVisible}
                setDocumentsLastVisible={props.setDocumentsLastVisible}
            />
            <Favorite timestamp={props.timestamp}/>
        </div>
    );
}

function Avatar(props) {
    const navigate = useNavigate();

    const [authorAvatarUrl, setAuthorAvatarUrl] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("avatarUrl")) {
            setAuthorAvatarUrl(docSnap.data()["avatarUrl"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (authorAvatarUrl !== "") {
        return (
            <div className="Read-Avatar">
                <img src={authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Time(props) {
    const endTimestamp = Date.now();

    const beginDate = moment(props.timestamp);
    const endDate = moment(endTimestamp);
    const diff = endDate.diff(beginDate);
    const diffDuration = moment.duration(diff);

    if (diffDuration.minutes() < 1) {
        return (
            <div className="Read-Time">
                <p>방금 전</p>
            </div>
        );
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="Read-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        );
    } else if (diffDuration.days() < 1) {
        return (
            <div className="Read-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        );
    } else {
        return (
            <div className="Read-Time">
                <p>{beginDate.format("YYYY-MM-DD")}</p>
            </div>
        );
    }
}

function Nickname(props) {
    const navigate = useNavigate();

    const [nickname, setNickname] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("nickname")) {
            setNickname(docSnap.data()["nickname"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (nickname !== "") {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    {nickname}
                </button>
            </div>
        );
    } else {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        );
    }
}

function Dropdown(props) {
    const navigate = useNavigate();

    const auth = getAuth();

    let isEdit = false;
    if (auth.currentUser !== null && auth.currentUser.uid === props.authorUid) {
        isEdit = true;
    }

    const [dropdown, setDropdown] = useState(false);

    const onDropdownClick = () => {
        if (dropdown === true) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onEditClick = () => {
        navigate("/edit", {state: {timestamp: props.timestamp, authorUid: props.authorUid}})
    }

    const onDeleteClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer === true) {
            const firestore = getFirestore();

            await deleteDoc(doc(firestore, "board", props.timestamp.toString()));
            await deleteDoc(doc(firestore, "metadata", props.timestamp.toString()));

            navigate("/");
        }
    }

    const onReportClick = () => {
        if (auth.currentUser !== null) {
            navigate("/report", {state: {postType: "post", timestamp: props.timestamp}});
        } else {
            navigate("/login");
        }
    }

    if (isEdit === true) {
        if (dropdown === true) {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Pencil size={24}/>
                    </botton>
                    <botton className="Read-Dropdown-Edit" onClick={onEditClick}>수정하기</botton>
                    <botton className="Read-Dropdown-Delete" onClick={onDeleteClick}>삭제하기</botton>
                </div>
            );
        } else {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Pencil size={24}/>
                    </botton>
                </div>
            );
        }
    } else {
        if (dropdown === true) {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Warning color="red" size={24}/>
                    </botton>
                    <botton className="Read-Dropdown-Report" onClick={onReportClick}>신고하기</botton>
                </div>
            );
        } else {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Warning color="red" size={24}/>
                    </botton>
                </div>
            );
        }
    }
}

function NumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });
            } else {
                setNumUps(numUps + 1);
                setClick(true);

                const upTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": upTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-NumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [clickDown, setClickDown] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClickDown(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());

        const onClick = async () => {
            if (clickDown === true) {
                setNumDowns(numDowns - 1);
                setClickDown(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });
            } else {
                setNumDowns(numDowns + 1);
                setClickDown(true);

                const downTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": downTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });
            }
        };

        if (clickDown === true) {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-NumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function NumComments(props) {
    const [numComments, setNumComments] = useState(0);

    const firestore = getFirestore();

    const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
    getDoc(summaryDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
            setNumComments(docSnap.data()["numComments"]);
        }
    });

    const onClick = () => {
        props.setTimestamp(props.timestamp);
        props.setAuthorUid(props.authorUid);

        props.setCommentLastVisible(undefined);
        props.setComments([]);

        props.setDocumentsLastVisible(undefined);
        props.setDocuments([]);
    }

    return (
        <div className="Read-NumComments">
            <button onClick={onClick}>
                <ChatsCircle size={24}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function Favorite(props) {
    const navigate = useNavigate();

    const [clickFavorite, setClickFavorite] = useState(false);

    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser !== null) {
            const firestore = getFirestore();

            const favoriteDocRef = doc(firestore, "user", auth.currentUser.uid, "favorite", props.timestamp.toString());
            getDoc(favoriteDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setClickFavorite(true);
                }
            });
        }
    }, []);

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const favoriteDocRef = doc(firestore, "user", auth.currentUser.uid, "favorite", props.timestamp.toString());

        const onClick = async () => {
            if (clickFavorite === true) {
                setClickFavorite(false);

                await deleteDoc(favoriteDocRef);
            } else {
                setClickFavorite(true);

                const favoriteTimestamp = Date.now();

                await setDoc(favoriteDocRef, {
                    "timestamp": props.timestamp,
                    "uid": auth.currentUser.uid,
                    "favoriteTimestamp": favoriteTimestamp,
                });
            }
        };

        if (clickFavorite === true) {
            return (
                <div className="Read-Favorite">
                    <button onClick={onClick}>
                        <Star color="#e7fbff" size={24}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-Favorite">
                    <button onClick={onClick}>
                        <Star size={24}/>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-Favorite">
                <button onClick={onClick}>
                    <Star size={24}/>
                </button>
            </div>
        );
    }
}

function Comments(props) {
    const comments = props.comments.map((item, i) => {
        return <Comment comment={item}/>;
    });

    return (
        <div className="Read-Comments">
            {comments}
        </div>
    );
}

function Comment(props) {
    return (
        <div className="Read-Comment">
            <Avatar
                authorUid={props.comment["commentAuthorUid"]}
            />
            <Time timestamp={props.comment["commentTimestamp"]}/>
            <Nickname
                authorUid={props.comment["commentAuthorUid"]}
            />
            <CommentBody content={props.comment["content"]}/>
            <CommentNumUps
                timestamp={props.comment["timestamp"]}
                commentTimestamp={props.comment["commentTimestamp"]}
            />
            <CommentNumDowns
                timestamp={props.comment["timestamp"]}
                commentTimestamp={props.comment["commentTimestamp"]}
            />
            <CommentNumReply
                timestamp={props.comment["timestamp"]}
                commentTimestamp={props.comment["commentTimestamp"]}
                commentAuthorUid={props.comment["commentAuthorUid"]}
            />
        </div>
    );
}

function CommentNumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });
            } else {
                setNumUps(numUps + 1);
                setClick(true);

                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-CommentNumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function CommentNumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    const firestore = getFirestore();

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"]);
            }
        });
    }, []);

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());

        const onClick = async () => {
            if (click === true) {
                setNumDowns(numDowns - 1);
                setClick(false);

                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });
            } else {
                setNumDowns(numDowns + 1);
                setClick(true);

                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });
            }
        };

        if (click === true) {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-CommentNumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function CommentNumReply(props) {
    const navigate = useNavigate();

    const [numReplies, setNumReplies] = useState(0);

    const auth = getAuth();

    const firestore = getFirestore();

    const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
    getDoc(summaryDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numReplies")) {
            setNumReplies(docSnap.data()["numReplies"]);
        }
    });

    const onClick = () => {
        if (auth.currentUser !== null) {
            navigate("/reply", {
                state: {
                    timestamp: props.timestamp,
                    commentTimestamp: props.commentTimestamp,
                    commentAuthorUid: props.commentAuthorUid
                }
            });
        } else {
            navigate("/login");
        }
    };

    return (
        <div className="Read-CommentNumReplies">
            <button onClick={onClick}>
                <ShareFat size={24}/>
                <p>{numReplies}</p>
            </button>
        </div>
    );
}

function CommentBody(props) {
    return (
        <div className="Read-CommentBody">
            <p>
                {props.content}
            </p>
        </div>
    );
}

function CommentInput(props) {
    const [content, setContent] = useState("");

    const onContentChange = (e) => {
        const {target: {name, value}} = e;

        setContent(value);
    };

    return (
        <div className="Read-CommentInput">
            <form>
                <input
                    className="Read-CommentInput-Content"
                    type="text"
                    placeholder="댓글"
                    required
                    value={content}
                    onChange={onContentChange}/>
            </form>
            <CommentSubmit
                timestamp={props.timestamp}
                content={content}
                setContent={setContent}
                setIsLoading={props.setIsLoading}
            />
        </div>
    );
}

function CommentSubmit(props) {
    const onClick = async () => {
        if (props.content !== "") {
            const content = props.content;
            props.setContent("");

            const auth = getAuth();
            const firestore = getFirestore();

            const commentTimestamp = Date.now();

            if (auth.currentUser !== null) {
                await updateDoc(doc(firestore, "metadata", props.timestamp.toString()), {
                    "numComments": increment(1),
                });

                await setDoc(doc(firestore, "board", props.timestamp.toString(), "comment", commentTimestamp.toString()), {
                    "commentAuthorUid": auth.currentUser.uid,
                    "content": content,
                    "timestamp": props.timestamp,
                    "commentTimestamp": commentTimestamp,
                    "numUps": 0,
                    "numDowns": 0,
                    "numReplies": 0,
                });

                await setDoc(doc(firestore, "metadata", props.timestamp.toString(), "comment", commentTimestamp.toString()), {
                    "commentAuthorUid": auth.currentUser.uid,
                    "commentTimestamp": commentTimestamp,
                });
            } else {
                alert("댓글 쓰기는 로그인하여야 가능합니다.")
            }
        }
    };

    return <div className="Read-CommentSubmit">
        <button onClick={onClick}>
            댓글 쓰기
        </button>
    </div>
}

function LoadComments(props) {
    const onClick = async () => {
        props.getNextComments();
    };

    return <div className="Read-LoadComments">
        <button onClick={onClick}>
            댓글 더보기
        </button>
    </div>
}

function NextDocuments(props) {
    const documents = props.documents.map((item, i) => {
        return <NextDocument
            timestamp={props.timestamp}
            document={item}
            toolbarOptions={props.toolbarOptions}
            setTimestamp={props.setTimestamp}
            setAuthorUid={props.setAuthorUid}
            setComments={props.setComments}
            setCommentLastVisible={props.setCommentLastVisible}
            setDocuments={props.setDocuments}
            setDocumentsLastVisible={props.setDocumentsLastVisible}
        />;
    });

    return (
        <div className="Read-NextDocuments">
            {documents}
        </div>
    );
}

function NextDocument(props) {
    if (props.document["timestamp"] < props.timestamp) {
        return (
            <div className="Read-NextDocument">
                <Avatar
                    authorUid={props.document["authorUid"]}
                />
                <Time timestamp={props.document["timestamp"]}/>
                <Nickname
                    authorUid={props.document["authorUid"]}
                />
                <Report
                    timestamp={props.document["timestamp"]}
                    authorUid={props.document["authorUid"]}
                />
                <ThumbnailContent
                    timestamp={props.document["timestamp"]}
                    authorUid={props.document["authorUid"]}
                    content={props.document["content"]}
                    toolbarOptions={props.toolbarOptions}
                    setTimestamp={props.setTimestamp}
                    setAuthorUid={props.setAuthorUid}
                    setComments={props.setComments}
                    setCommentLastVisible={props.setCommentLastVisible}
                    setDocuments={props.setDocuments}
                    setDocumentsLastVisible={props.setDocumentsLastVisible}
                />
                <Hashtags
                    hashtags={props.document["hashtags"]}
                />
                <NumUps
                    timestamp={props.document["timestamp"]}
                />
                <NumDowns
                    timestamp={props.document["timestamp"]}
                />
                <NumComments
                    timestamp={props.document["timestamp"]}
                    authorUid={props.document["authorUid"]}
                    setTimestamp={props.setTimestamp}
                    setAuthorUid={props.setAuthorUid}
                />
                <Favorite
                    timestamp={props.document["timestamp"]}
                />
            </div>
        );
    }
}

function Report(props) {
    const navigate = useNavigate();

    const auth = getAuth();

    const [dropdown, setDropdown] = useState(false);

    const onDropdownClick = () => {
        if (dropdown === true) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onReportClick = () => {
        if (auth.currentUser !== null) {
            if (auth.currentUser.uid !== props.authorUid) {
                navigate("/report", {state: {postType: "post", timestamp: props.timestamp}});
            } else {
                alert("자기가 쓴 글은 신고할 수 없습니다.");
            }
        } else {
            navigate("/login");
        }
    }

    if (dropdown === true) {
        return (
            <div className="Read-Report">
                <botton className="Read-Report-Dropdown" onClick={onDropdownClick}>
                    <Warning color="red" size={24}/>
                </botton>
                <botton className="Read-Report-Report" onClick={onReportClick}>신고하기</botton>
            </div>
        );
    } else {
        return (
            <div className="Read-Report">
                <botton className="Read-Report-Dropdown" onClick={onDropdownClick}>
                    <Warning color="red" size={24}/>
                </botton>
            </div>
        );
    }
}

function ThumbnailContent(props) {
    const onClick = () => {
        props.setTimestamp(props.timestamp);
        props.setAuthorUid(props.authorUid);

        props.setCommentLastVisible(undefined);
        props.setComments([]);

        props.setDocumentsLastVisible(undefined);
        props.setDocuments([]);
    }

    const parsedContent = parse(props.content);

    if (Array.isArray(parsedContent) === false) {
        return (
            <div className="Read-ThumbnailContent">
                <button onClick={onClick}>
                    {parsedContent}
                </button>
            </div>
        );
    } else {
        if (recursiveLength(parsedContent) >= 10) {
            console.log(extractContent(parsedContent, 2, 0));

            return (
                <div className="Read-ThumbnailContent">
                    <button onClick={onClick}>
                        {extractContent(parsedContent, 0, 0)}
                        {extractContent(parsedContent, 1, 0)}
                        {extractContent(parsedContent, 2, 0)}
                        {extractContent(parsedContent, 3, 0)}
                        {extractContent(parsedContent, 4, 0)}
                        {extractContent(parsedContent, 5, 0)}
                        {extractContent(parsedContent, 6, 0)}
                        {extractContent(parsedContent, 7, 0)}
                        {extractContent(parsedContent, 8, 0)}
                        {extractContent(parsedContent, 9, 0)}
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-ThumbnailContent">
                    <button onClick={onClick}>
                        {parsedContent}
                    </button>
                </div>
            );
        }
    }
}

function DocumentStart() {
    return (
        <div className="Read-DocumentStart">
            <p>
                ------------------글 화면------------------
            </p>
        </div>
    );
}

function CommentsStart() {
    return (
        <div className="Read-CommentsStart">
            <p>
                -------------------댓글 시작-------------------
            </p>
        </div>
    );
}

function NextDocumentsStart() {
    return (
        <div className="Read-NextDocumentsStart">
            <p>
                -----------------다음 글 시작-----------------
            </p>
        </div>
    );
}

function Body(props) {
    return (
        <div className="Read-Body">
            <LeftSideBar/>
            <DocumentStart/>
            <Document
                timestamp={props.timestamp}
                authorUid={props.authorUid}
                content={props.content}
                hashtags={props.hashtags}
                numUps={props.numUps}
                numDowns={props.numDowns}
                toolbarOptions={props.toolbarOptions}
                setTimestamp={props.setTimestamp}
                setAuthorUid={props.setAuthorUid}
                setComments={props.setComments}
                setCommentLastVisible={props.setCommentLastVisible}
                setDocuments={props.setDocuments}
                setDocumentsLastVisible={props.setDocumentsLastVisible}
            />
            <CommentInput
                timestamp={props.timestamp}
                setIsLoading={props.setIsLoading}
            />
            <CommentsStart/>
            <Comments timestamp={props.timestamp} comments={props.comments}/>
            <LoadComments getNextComments={props.getNextComments}/>
            <NextDocumentsStart/>
            <NextDocuments
                timestamp={props.timestamp}
                setComments={props.setComments}
                setCommentLastVisible={props.setCommentLastVisible}
                documents={props.documents}
                setDocuments={props.setDocuments}
                setDocumentsLastVisible={props.setDocumentsLastVisible}
                toolbarOptions={props.toolbarOptions}
                setTimestamp={props.setTimestamp}
                setAuthorUid={props.setAuthorUid}
            />
            <RightSideBar/>
        </div>
    );
}

function recursiveLength(content) {
    if (Array.isArray(content) === true) {
        let length = 0;
        for (let child of content) {
            if (Array.isArray(child.props.children) === true) {
                length += recursiveLength(child);
            } else {
                length += 1;
            }
        }
        return length;
    } else if (content.hasOwnProperty("props") === true &&
        content.props.hasOwnProperty("children") === true &&
        Array.isArray(content.props.children) === true) {
        let length = 0;
        for (let child of content.props.children) {
            if (child.hasOwnProperty("props") === true &&
                child.props.hasOwnProperty("children") === true &&
                Array.isArray(child.props.children) === true) {
                length += recursiveLength(child);
            } else {
                length += 1;
            }
        }
        return length;
    } else {
        return 1;
    }
}

function extractContent(content, pos, accum) {
    if (Array.isArray(content) === true) {
        for (let i = 0; i < content.length; i++) {
            const child = content[i];

            if (accum <= pos && accum + recursiveLength(child) > pos) {
                return extractContent(child, pos, accum);
            } else if (accum > pos) {
                return;
            } else {
                accum += recursiveLength(child);
            }
        }
    } else if (content.hasOwnProperty("props") === true &&
        content.props.hasOwnProperty("children") === true &&
        Array.isArray(content.props.children) === true) {
        for (let i = 0; i < content.props.children.length; i++) {
            const child = content.props.children[i];

            if (accum <= pos && accum + recursiveLength(child) > pos) {
                return extractContent(child, pos, accum);
            } else if (accum > pos) {
                return;
            } else {
                accum += recursiveLength(child);
            }
        }
    } else {
        if (pos === accum) {
            return content;
        }
    }
}
